<template>
    <div class="container">
        <div class="max-w-6xl mx-auto gm-padding-y flex gap-4">
            <!--                    <FaceRecordingPlayer-->
            <!--                        class="w-2/3"-->
            <!--                        audio='/static/callsamples/fundraising1.mp3'-->
            <!--                        :src="michael"-->
            <!--                    />-->

            <FacePlayer
                class="grow w-2/3 hidden lg:flex"
                variant="mark"
                :src="mark"
            >
                <template #title>
                    <p class="">
                        Listen to Mark's Voice.
                    </p>
                </template>
            </FacePlayer>
            <!--        <HomepageVideo/>-->

            <div class="w-full" id="democall">
                <div class="bg-gray-200 w-full flex-grow rounded-lg p-6 relative">
                    <p class="text-2xl mb-4 font-bold">
                        Try a free test conversation right now.
                    </p>

                    <div class="flex gap-4 items-center">
                        <img :src="mark" class="w-16 rounded-full shrink-0 aspect-square grow-0"/>

                        <p class="text-xl">
                            Mark (Demo Agent / Real Estate)
                            <br>
                            <AppBadge class="bg-primary text-white mr-1">
                                Available
                            </AppBadge>
                            <AppBadge class="bg-primary text-white">
                                English
                            </AppBadge>
                        </p>
                    </div>


                    <form
                        v-if="!submitted"
                        @submit.prevent="submit"
                        method="post" class="space-y-4 my-6 bg-white p-4 rounded-lg">
                        <!--                <div>-->
                        <!--                    <InputLabel for="Name" value="Name"/>-->
                        <!--                    <TextInput-->
                        <!--                        id="name"-->
                        <!--                        v-model="form.name"-->
                        <!--                        type="text"-->
                        <!--                        class="mt-1 block w-full"-->
                        <!--                        autocomplete="phone"-->
                        <!--                        placeholder="Your Name"-->
                        <!--                    />-->
                        <!--                    <InputError :message="form.errors.name" class="mt-2"/>-->
                        <!--                </div>-->
                        <!--                <div>-->
                        <!--                    <InputLabel for="Email" value="Email"/>-->
                        <!--                    <TextInput-->
                        <!--                        id="Email"-->
                        <!--                        v-model="form.email"-->
                        <!--                        type="text"-->
                        <!--                        class="mt-1 block w-full"-->
                        <!--                        autocomplete="phone"-->
                        <!--                        placeholder="Email Address"-->
                        <!--                    />-->
                        <!--                    <InputError :message="form.errors.email" class="mt-2"/>-->
                        <!--                </div>-->
                        <div>
                            <InputLabel for="phone" value="Phone"/>
                            <AppPhoneField
                                id="phone"
                                v-model="form.number"
                            />
                            <InputError :message="form.errors.number" class="mt-2"/>
                        </div>

                        <!--                <InputLabel for="terms" class="cursor-pointer">-->
                        <!--                    <div class="flex items-center">-->
                        <!--                        <Checkbox id="terms" v-model:checked="form.terms" name="terms" required />-->

                        <!--                        <div class="ms-2">-->
                        <!--                            I agree to the <a target="_blank" :href="route('terms.show')" class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">Terms of Service</a> and <a target="_blank" :href="route('policy.show')" class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">Privacy Policy</a>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <!--                    <InputError class="mt-2" :message="form.errors.terms" />-->
                        <!--                </InputLabel>-->

                        <div class="flex max-lg:flex-col gap-8 items-center">
                            <!--                        <InputLabel for="marketing" class="cursor-pointer">-->
                            <!--                            <div class="flex items-center">-->
                            <!--                                <Checkbox id="marketing" v-model:checked="form.marketing" name="marketing"/>-->

                            <!--                                <div class="ms-2">-->
                            <!--                                    I agree to receive marketing communications from Greetmate via phone and email.-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!--                            <InputError class="mt-2" :message="form.errors.marketing"/>-->
                            <!--                        </InputLabel>-->

                            <p class="text-xs">
                                By submitting your phone number, you consent to being contacted. You also agree to the
                                use of your contact
                                information for marketing purposes, in accordance with our Privacy Policy. You may opt
                                out
                                at any time.
                            </p>

                            <div class="text-right">
                                <PrimaryButton
                                    @click="submit"
                                    :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                    Call Me Now
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                    <div v-else class="bg-white py-16 mt-6 rounded-lg">
                        <div class="text-center">
                            <p class="text-lg">
                                Thank you for your interest!
                            </p>
                            <p class="text-sm">
                                We will call you shortly.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import FacePlayer from "@/Components/Marketing/FacePlayer.vue";
import AppBadge from "@/Components/AppBadge.vue";
import mark from '../../../images/voices/mark.png?format=webp&h=400';
import {useForm} from "@inertiajs/vue3";
import InputLabel from "@/Components/InputLabel.vue";
import InputError from "@/Components/InputError.vue";
import AppPhoneField from "@/Components/AppPhoneField.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
//import {route} from "ziggy-js";
import {onMounted, ref} from "vue";

onMounted(() => {
    if (window.location.href.includes('demoCallForm')) {
        submitted.value = true
    }
})

const form = useForm({
    name: '',
    email: '',
    number: '',
    terms: false,
    marketing: false,
})

const submitted = ref(false)
const submit = () => {

    form.post(route('audio-example.test-call'), {
        preserveScroll: true,
        onSuccess: () => {
            try {
                // @ts-ignore
                fbq('trackCustom', 'DemoCall')
            } catch (e) {
                console.log(e);
            }

            window.location.href = '?demoCallForm=true'
            submitted.value = true
        }
    })
}
</script>
