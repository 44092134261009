<template>
    <div class="container gm-padding-y">

        <div class="">
            <h3 class="!leading-[1.5] font-bold">
               Frequently Asked <span class="text-callout-primary">Questions</span>.
            </h3>

            <p class="mt-6 max-w-4xl">
                Here are some of the most frequently asked questions about our platform. If you have any other questions, feel free to contact us.
            </p>
        </div>

        <dl class="mt-10 divide-y divide-gray-900/10">
            <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
                <dt>
                    <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900 hover:bg-gray-100 py-3">
                        <span class="text-base/7 font-semibold">{{ faq.question }}</span>
                        <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="size-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="size-6" aria-hidden="true" />
                </span>
                    </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" class="mt-2 pr-12">
                    <p class="text-base/7 text-gray-600">{{ faq.answer }}</p>
                </DisclosurePanel>
            </Disclosure>
        </dl>

    </div>

</template>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
    {
        question: "What is Greetmate.ai?",
        answer: "Greetmate.ai is an AI-powered platform that automates business phone calls by acting as a virtual receptionist, scheduling appointments, answering questions, and more."
    },
    {
        question: "How does Greetmate handle inbound calls?",
        answer: "Greetmate uses AI voice agents to manage inbound calls, gather information, answer queries, and route calls to the right person when needed."
    },
    {
        question: "Can Greetmate make outbound calls?",
        answer: "Yes, Greetmate can make outbound calls (Pro plan required) for tasks such as follow-ups, customer feedback surveys, or reminders."
    },
    {
        question: "Does Greetmate integrate with existing tools?",
        answer: "Yes, Greetmate integrates with tools like Zapier, Google Sheets, Google Calendar, and can also integrate with your existing CRM or ERP systems through webhooks and Zapier. If you need a custom solution, you can hire us to build it for you."
    },
    {
        question: "What voices are available on Greetmate?",
        answer: "Greetmate offers thousands of human-like voices through ElevenLabs. Choose from different ready-to-use voices or bring your own voice with your ElevenLabs API key and voice ID."
    },
    {
        question: "Is Greetmate suitable for small businesses?",
        answer: "Yes, Greetmate is designed for businesses of all sizes, providing affordable and customizable solutions to automate phone calls."
    },
    {
        question: "How does the no-code workflow builder work?",
        answer: "The no-code workflow builder allows you to create and customize call scenarios easily, including collecting caller information and automating tasks."
    },
    {
        question: "Can Greetmate handle appointment scheduling?",
        answer: "Yes, Greetmate can schedule appointments and integrate with Google Calendar, Zoho Bookings, or your own system through webhooks and Zapier."
    },
    {
        question: "Does Greetmate support 24/7 availability?",
        answer: "Yes, Greetmate operates 24/7, ensuring that your business never misses a call or customer inquiry."
    },
    {
        question: "How does Greetmate handle call transcriptions?",
        answer: "Greetmate records and transcribes calls, allowing you to review conversations and gather insights for your business."
    },
    {
        question: "Can Greetmate send emails and SMS?",
        answer: "Yes, Greetmate can send emails and SMS based on your call workflows, including confirmations and notifications."
    },
    {
        question: "How does Greetmate ensure customer satisfaction?",
        answer: "Greetmate enhances customer satisfaction by reducing wait times, automating repetitive tasks, and transferring calls to human agents when needed."
    },
    {
        question: "What industries does Greetmate support?",
        answer: "Greetmate is suitable for various industries, including medical offices, real estate, auto repair shops, and more."
    },
    {
        question: "Is a phone number included with Greetmate?",
        answer: "Yes, Greetmate provides a phone number with your subscription. You can also configure call forwarding on your end to simply forward calls from your existing number to Greetmate."
    },
    {
        question: "Does Greetmate offer a free trial?",
        answer: "Yes, Greetmate offers a free trial so you can explore its features before committing."
    },
    {
        question: "What is the Founder’s Discount?",
        answer: "The Founder’s Discount offers up to 34% off on Greetmate subscriptions, with no long-term commitment required."
    },
    {
        question: "How does Greetmate help with customer feedback?",
        answer: "Greetmate can automate customer feedback surveys by triggering outbound calls and collecting data through customizable workflows."
    },
    {
        question: "Can Greetmate transfer calls to human agents?",
        answer: "Yes, Greetmate can transfer calls to human agents when necessary, ensuring callers receive the help they need."
    },
    {
        question: "How does Greetmate help reduce workload?",
        answer: "Greetmate automates repetitive tasks like answering calls, scheduling appointments, and gathering customer information, freeing up your team for high-value activities."
    },
];

</script>
